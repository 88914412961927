import i18n from 'i18next';
import Constants from './Constants';
import {MediaContentType} from '../model/baseMedia';

export enum IWellKnownFolder {
   root = 'root',
   recentMedia = 'rm',
   allVideo = 'av', // these are truncated to 2 chars so they dont risk any overlap with valid hashes
   allImage = 'am' // that may exist in extreme corner-case with any len > 4
}

export const isWellKnownFolder = (folderHash: string): boolean => Object.values(IWellKnownFolder).includes(folderHash as IWellKnownFolder);

export const getWellKnownFolderDisplayName = (folderHash: IWellKnownFolder) => {
   switch (folderHash) {
      case IWellKnownFolder.root: {
         return i18n.t('MyLibrary');
      }
      case IWellKnownFolder.recentMedia: {
         return i18n.t('sidebar.recentMedia');
      }
      case IWellKnownFolder.allVideo: {
         return i18n.t('sidebar.allVideos');
      }
      case IWellKnownFolder.allImage: {
         return i18n.t('sidebar.allImages');
      }
      default: {
         return '';
      }
   }
};

export const doesMediaTypeBelongInWellKnownFolder = (mediaType: string, folderHash: IWellKnownFolder): boolean => {
   switch (folderHash) {
      case IWellKnownFolder.root:
         return true;
      case IWellKnownFolder.recentMedia:
         return true;
      case IWellKnownFolder.allImage:
         return mediaType === MediaContentType.Image;
      case IWellKnownFolder.allVideo:
         return mediaType === MediaContentType.Video;
      default:
         return false;
   }
};

export const getWellKnownFolderPermalink = (folderHash: IWellKnownFolder) => {
   switch (folderHash) {
      case IWellKnownFolder.root: {
         return Constants.navigation.myLibraryLink;
      }
      case IWellKnownFolder.recentMedia: {
         return Constants.navigation.myLibraryRecentMediaLink;
      }
      case IWellKnownFolder.allVideo: {
         return Constants.navigation.myLibraryAllVideosLink;
      }
      case IWellKnownFolder.allImage: {
         return Constants.navigation.myLibraryAllImagesLink;
      }
      default: {
         return '#';
      }
   }
};
