import Constants, {EntryPoint, NavigationTabTypes} from '../constants/Constants';
import config from '../service/config';
import i18n from 'i18next';
import {INavigationTab} from '../component/tabs/Tabs';
import {matchPath} from 'react-router-dom';

const isUsersLibrary = () => config.entryPoint !== EntryPoint.library || config.libraryConfig?.loggedInUserIsProfileOwner;

export const getScreencastTabs = (): INavigationTab[] => {
   const tabs = [
      {
         text: isUsersLibrary() ? i18n.t('MyLibrary') : i18n.t('LibraryForUser', {userDisplayName: (config.libraryConfig?.profileDisplayName || config.feedConfig?.profileDisplayName)}),
         isSelected: true,
         which: NavigationTabTypes.myFolders,
         href: isUsersLibrary() ? Constants.navigation.myLibraryLink : `/users/${(config.libraryConfig?.profileTechSmithId || config.feedConfig?.profileTechSmithId)}`,
         matcher: pathname => matchPath(pathname, {path: [Constants.navigation.myLibraryLink, '/library.html'], exact: true, strict: false}) !== null
      }
   ] as INavigationTab[];

   if (config.featureSwitches.EnableTeams) {
      tabs.push({
         text: i18n.t('Teams'),
         isSelected: true,
         which: NavigationTabTypes.teams,
         href: Constants.navigation.teamsLink,
         matcher: pathname => matchPath(pathname, {path: Constants.navigation.teamsLink, exact: true, strict: false}) !== null
      });
   }

   tabs.push({
      text: i18n.t('collections.all'),
      isSelected: true,
      which: NavigationTabTypes.allCollections,
      href: Constants.navigation.myCollectionsLink,
      matcher: pathname => matchPath(pathname, {path: Constants.navigation.manageCollectionBasePath, exact: true, strict: false}) !== null
   });

   return tabs;
};

export const getScreencastHeaderTabs = (): INavigationTab[] => {
   const tabs: INavigationTab[] = [
      {
         text: i18n.t('MyLibrary'),
         isSelected: false,
         which: NavigationTabTypes.myLibrary,
         href: Constants.navigation.myLibraryLink.toLowerCase(),
         matcher: pathName => matchPath(pathName, {path: [Constants.navigation.myLibraryLink, Constants.navigation.local.myLibraryLink, Constants.navigation.myCollectionsLink], exact: true}) !== null
            || matchPath(pathName, {path: [`${Constants.navigation.foldersRoot}/:hash`, `${Constants.navigation.manageCollectionBasePath}/:id`], exact: true}) !== null
      }
   ];
   return tabs;
};

export const getNavLinkTabs = (isNavMenu: boolean) => {
   if (config.featureSwitches.IsScreencast) {
      return getScreencastTabs();
   }
   const tabs: INavigationTab[] = [
      {
         text: i18n.t('library.tabName'),
         isSelected: false,
         which: NavigationTabTypes.myLibrary,
         href: Constants.navigation.myLibraryLink.toLowerCase(),
         matcher: pathName => matchPath(pathName, {path: [Constants.navigation.myLibraryLink, Constants.navigation.local.myLibraryLink, Constants.navigation.myCollectionsLink], exact: true}) !== null
            || isNavMenu && matchPath(pathName, {path: [`${Constants.navigation.foldersRoot}/:hash`, `${Constants.navigation.manageCollectionBasePath}/:id`], exact: true}) !== null
      }
   ];
   tabs.push({
      text: i18n.t('collections.feed'),
      isSelected: false,
      which: NavigationTabTypes.myFeed,
      href: Constants.navigation.myFeedLink.toLowerCase(),
      matcher: pathName => matchPath(pathName, {path: [Constants.navigation.myFeedLink, Constants.navigation.local.myFeedLink], exact: true}) !== null
         || isNavMenu && matchPath(pathName, {path: `${Constants.navigation.collectionsViewBasePath}/:id`, exact: true}) !== null
   });
   !config.featureSwitches.DisableActivityPage && tabs.push({
      text: i18n.t('accessibility.tabName'),
      isSelected: false,
      which: NavigationTabTypes.accessibility,
      href: Constants.navigation.accessibilityLink.toLowerCase()
   });
   config.isAdmin && tabs.push({
      text: i18n.t('SiteAdmin'),
      isSelected: false,
      which: NavigationTabTypes.admin,
      href: Constants.navigation.admin.adminLink
   });

   const location = (window.location.pathname || '').toLowerCase();

   for (const tab of tabs) {
      const defaultMatcherFunction = (pathName: string) => pathName.indexOf(tab.href.toLowerCase()) === 0;
      const matcherFunction = tab.matcher || defaultMatcherFunction;

      tab.isSelected = matcherFunction(location);
   }

   return tabs;
};

export const generateLibraryTabs = (): INavigationTab[] => {
   if (config.featureSwitches.IsScreencast) {
      return getScreencastTabs();
   }
   const tabs: INavigationTab[] = [{
      text: isUsersLibrary() ? i18n.t('MyLibrary') : i18n.t('LibraryForUser', {userDisplayName: config.libraryConfig?.profileDisplayName}),
      isSelected: true,
      which: NavigationTabTypes.myFolders,
      href: isUsersLibrary() ? Constants.navigation.myLibraryLink : `/users/${config.libraryConfig?.profileTechSmithId}`,
      matcher: pathname => matchPath(pathname, {path: [Constants.navigation.myLibraryLink, '/library.html'], exact: true, strict: false}) !== null
   }];

   if (config.sidebarShowMyCollections) {
      tabs.push({
         text: i18n.t('collections.mine'),
         isSelected: true,
         which: NavigationTabTypes.myCollections,
         href: Constants.navigation.myCollectionsLink,
         matcher: pathname => matchPath(pathname, {path: Constants.navigation.myCollectionsLink, exact: true, strict: false}) !== null
      });
   }

   return tabs;
};

export const generateTeamTabs = (): INavigationTab[] => getScreencastTabs();

export const generateFeedTabs = (): INavigationTab[] => {
   if (config.featureSwitches.IsScreencast) {
      return getScreencastTabs();
   }
   const tabs: INavigationTab[] = [{
      text: i18n.t('collections.followed'),
      isSelected: true,
      href: Constants.navigation.myFeedLink,
      which: NavigationTabTypes.followedCollections,
      matcher: pathname => matchPath(pathname, {path: [Constants.navigation.myFeedLink, Constants.navigation.local.myFeedLink], exact: true, strict: false}) !== null
   }];
   return tabs;
};
