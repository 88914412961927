import React from 'react';
import styled from 'styled-components';
import config from '../../service/config';
import SideBarMenuLogo from '../../static/svg/SidebarHeaderLogo.svg';
import HeaderLogo from '../../static/svg/HeaderLogo.svg';
import ScreencastHeaderLogo from '../../static/svg/ScreencastHeaderLogo.svg';
import ScreencastMobileHeaderLogo from '../../static/svg/ScreencastMobileHeaderLogo.svg';
import {TscStyles} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import {ScreenReaderOnly} from '../util/StyledElements';
import {EntryPoint} from '../../constants/Constants';
import cssConstants from '../../constants/cssConstants';

export const Logo = styled.a`
   display: flex;
   align-items: center;
   line-height: ${cssConstants.headerHeight};
   max-width: 12rem;
   width: 100%;

   svg {
      display: block;
      width: 100%;
      max-height: ${cssConstants.headerHeight};
      max-width: 12rem;
      fill: ${TscStyles.color.text.light};
   }

   img {
      display: block;
      height: auto;
      max-height: 2.75rem;
      width: auto;
      max-width: 12rem;
      padding: 0.25rem 0;
   }
`;

interface ISiteLogoProps {
   isSideBar: boolean;
}

const SiteLogo: React.FC<ISiteLogoProps> = ({isSideBar}) => {
   const {t} = useTranslation();
   const siteLogoLinkUrl = config.user.IsSignedIn ? config.environmentData.SiteLogoLinkUrl : window.location.origin;

   const getLogoImage = (): React.JSX.Element => {
      if (config.featureSwitches.IsScreencast && config.environmentData.CustomSiteLogoUrl && config.entryPoint === EntryPoint.watch) {
         return <img className="logo" alt={t('sidebar.siteLogoAltText', {applicationFullName: config.applicationFullName})} src={config.environmentData.CustomSiteLogoUrl} />;
      } else if (config.environmentData.SiteLogoImageUrl) {
         return <img className="logo" alt={t('sidebar.siteLogoAltText', {applicationFullName: config.applicationFullName})} src={config.environmentData.SiteLogoImageUrl} />;
      }
      let DefaultLogo = isSideBar ? <SideBarMenuLogo /> : <HeaderLogo />;
      if (config.featureSwitches.EnableScreencastProEntitlement) {
         DefaultLogo = isSideBar ? <ScreencastMobileHeaderLogo /> : <ScreencastHeaderLogo />;
      }
      return DefaultLogo;
   };

   return (
      <Logo href={siteLogoLinkUrl}>
         {getLogoImage()}
         <ScreenReaderOnly>{config.user.IsSignedIn ? t('MyLibrary') : `${config.applicationShortName} ${t('Home')}`}</ScreenReaderOnly>
      </Logo>
   );
};

export default SiteLogo;
