import {AxiosProgressEvent, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import ApiUtil from '../util/ApiUtil';
import Constants from '../constants/Constants';
import {IBaseMedia} from '../model/baseMedia';

// some of this resumable code was borrowed from the original angular source and adapted to our react use, some references to lines for funcs are included

const uploadBaseUrl = '/api/v1/upload';
// we chose '1MB' so just hardcoded value from orig calculation source: https://github.com/danialfarid/ng-file-upload/blob/a4c4187c11bcc1a7eb81cf73171ad0110ac2e2f6/src/upload.js#L354
const resumableChunkSize = 1048576;

interface IUploadChunk {
   slice: Blob;
   chunkSize: number;
   currentChunkSize: number;
   chunkNumber: number;
}

// https://github.com/danialfarid/ng-file-upload/blob/a4c4187c11bcc1a7eb81cf73171ad0110ac2e2f6/src/upload.js#L32
const isResumeSupported = () => window.Blob && window.Blob.prototype.slice;

const validateStatus = (status: number) => {
   const firstNonSuccessCode = 300;
   // Present returns 415 for invalid media dimensions with some additional information in the response, so we want to prevent Axios from throwing an error and instead throw in ApiUtil.executeRequestWithErrorHandling
   return status >= Constants.statusCodes.ok && (status < firstNonSuccessCode || status === Constants.statusCodes.unsupportedMediaType);
};

const getModifiedProgressHandler = (currentChunk: IUploadChunk, totalSize: number, existingProgressHandler?: (progressEvent: AxiosProgressEvent) => void) => {
   if (!existingProgressHandler) {
      return existingProgressHandler;
   }

   // we modify the current progress event being passed, taking into account the number of chunks, and total file size,
   // then execute the original function with modified progress data passed into it
   return (progressEvent: AxiosProgressEvent) => {
      const modifiedProgressEvent: AxiosProgressEvent = {
         ...progressEvent,
         loaded: (currentChunk.chunkNumber * currentChunk.chunkSize) + progressEvent.loaded,
         total: totalSize
      };
      existingProgressHandler(modifiedProgressEvent);
   };
};

const uploadOneChunk = async (uploadEndpointPath: string, currentChunk: IUploadChunk, fileSize: number, fileName: string, handleUploadProgress: (progressEvent: AxiosProgressEvent) => void, cancelTokenSource: CancelTokenSource) => {
   const requestConfig: AxiosRequestConfig = {
      headers: {'Content-Type': 'multipart/form-data', 'X-Requested-By': 'presentation-web-client'},
      onUploadProgress: getModifiedProgressHandler(currentChunk, fileSize, handleUploadProgress),
      cancelToken: cancelTokenSource.token,
      validateStatus: validateStatus
   };

   const postData = new FormData();
   postData.append('_chunkSize', currentChunk.chunkSize.toString());
   postData.append('_currentChunkSize', currentChunk.currentChunkSize.toString());
   postData.append('_chunkNumber', currentChunk.chunkNumber.toString());
   postData.append('_totalSize', fileSize.toString());
   postData.append('file', new File([currentChunk.slice], fileName)); // we turn the chunk back into a 'File' here so we can give it a name property which the upload endpoint relies on for chunk stitching
   // upload endpoint returns 202 for chunk, 201 when complete, replace returns 202 for chunk, 204 for complete
   const response: AxiosResponse<IBaseMedia> = await ApiUtil.executePostAsync(uploadEndpointPath, [Constants.statusCodes.created, Constants.statusCodes.accepted, Constants.statusCodes.noContent], postData, requestConfig);
   return response.data;
};

const uploadInChunks = async (file: File, uploadEndpointPath: string, handleUploadProgress: (progressEvent: AxiosProgressEvent) => void, cancelTokenSource: CancelTokenSource) => {
   let uploadStartByte = 0;
   let uploadStartChunk = 0;

   try {
      const resumableUploadStatus = await ApiUtil.executeGetWithoutCacheAsync<{size: number}>(`${uploadBaseUrl}/resume/${file.name}/${file.size}`, [Constants.statusCodes.ok, Constants.statusCodes.notFound]);
      uploadStartByte = parseInt((resumableUploadStatus.data.size === null ? resumableUploadStatus.data : resumableUploadStatus.data.size).toString(), 10);
      uploadStartChunk = Math.floor(uploadStartByte / resumableChunkSize);
   } catch {
      // nothing to do, may get a 404 if this is not an upload being resumed, or any other error with resume endpoint, just start fresh
   }

   let currentChunkStart = uploadStartByte;
   let currentChunkNumber = uploadStartChunk;
   let baseMediaResponse: IBaseMedia;
   while (currentChunkStart < file.size) {
      const currentChunkEnd = Math.min(currentChunkStart + resumableChunkSize, file.size);

      const chunk: IUploadChunk = {
         slice: new File([file.slice(currentChunkStart, currentChunkEnd)], file.name),
         chunkSize: resumableChunkSize,
         currentChunkSize: currentChunkEnd - currentChunkStart,
         chunkNumber: currentChunkNumber
      };

      // we inline this call in the while loop so it executes in serial, anything fancier seemed to overlap upload calls which is no good
      baseMediaResponse = await uploadOneChunk(uploadEndpointPath, chunk, file.size, file.name, handleUploadProgress, cancelTokenSource);

      currentChunkNumber++;
      currentChunkStart = currentChunkEnd;
   }

   return baseMediaResponse;
};

const replaceMedia = async (hash: string, file: File, handleUploadProgress: (progressEvent: AxiosProgressEvent) => void, cancelTokenSource: CancelTokenSource) => {
   const uploadEndpointPath = `${uploadBaseUrl}/${hash}/replace`;
   if (isResumeSupported()) {
      return await uploadInChunks(file, uploadEndpointPath, handleUploadProgress, cancelTokenSource);
   }

   const requestConfig: AxiosRequestConfig = {headers: {'Content-Type': 'multipart/form-data', 'X-Requested-By': 'presentation-web-client'}, onUploadProgress: handleUploadProgress, cancelToken: cancelTokenSource.token};
   const postData = new FormData();
   postData.append('file', file);
   const response: AxiosResponse<IBaseMedia> = await ApiUtil.executePostAsync(uploadEndpointPath, [Constants.statusCodes.noContent], postData, requestConfig);
   return response.data;
};

const uploadMedia = async (parentFolderHash: string, teamId: string, file: File, handleUploadProgress: (progressEvent: AxiosProgressEvent) => void, cancelTokenSource: CancelTokenSource) => {
   const uploadEndpointPath = (teamId && !parentFolderHash) ? `${uploadBaseUrl}/team/${teamId}` : `${uploadBaseUrl}/${parentFolderHash}`;

   if (isResumeSupported()) {
      return await uploadInChunks(file, uploadEndpointPath, handleUploadProgress, cancelTokenSource);
   }

   const requestConfig: AxiosRequestConfig = {headers: {'Content-Type': 'multipart/form-data', 'X-Requested-By': 'presentation-web-client'}, onUploadProgress: handleUploadProgress, cancelToken: cancelTokenSource.token, validateStatus: validateStatus};
   const postData = new FormData();
   postData.append('file', file);
   const response: AxiosResponse<IBaseMedia> = await ApiUtil.executePostAsync(uploadEndpointPath, [Constants.statusCodes.created], postData, requestConfig);
   return response.data;
};

const uploadCustomLogo = async (file: File, handleUploadProgress: (progressEvent: AxiosProgressEvent) => void, cancelTokenSource: CancelTokenSource) => {
   const uploadEndpointPath = `${uploadBaseUrl}/customLogo`;

   const requestConfig: AxiosRequestConfig = {headers: {'Content-Type': 'multipart/form-data', 'X-Requested-By': 'presentation-web-client'}, onUploadProgress: handleUploadProgress, cancelToken: cancelTokenSource.token, validateStatus: validateStatus};
   const postData = new FormData();
   postData.append('file', file);
   const response: AxiosResponse<string> = await ApiUtil.executePostAsync(uploadEndpointPath, [Constants.statusCodes.created], postData, requestConfig);
   return response.data;
};

export default {
   replaceMedia,
   uploadMedia,
   uploadCustomLogo
};
