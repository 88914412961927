import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {TscCheckboxAlignment, InlineLabeledCheckbox} from '@techsmith/tsc-cloud-style-guide';
import '../../static/css/modal.less';
import CopyLinkFormCollection from './CopyLinkFormCollection';
import {UpsertQueryParamInUrl} from '../util/QueryParamsHelper';
import Constants from '../../constants/Constants';
import {importMediaInterface} from '../../hooks/dynamicImport';

const ShareMediaLink: React.FC<IShareMediaLinkProps> = ({link, text, setCurrentCopy, currentCopy}) => {
   const {t} = useTranslation();
   const [shareLinkState, setShareLinkState] = useState({mediaLink: link, mediaCurrentTimeInSeconds: 0, includeCurrentTimeInMediaLink: false} as IShareMediaLinkState);

   useEffect(() => {
      setShareLinkState({...shareLinkState, mediaLink: getLink(shareLinkState.includeCurrentTimeInMediaLink)});
   }, [link, shareLinkState.mediaLink]);

   useEffect(() => {
      void importMediaInterface().then(mediaInterface => {
         const mediaCurrentTimeInSeconds = mediaInterface.currentTime ? Math.round(mediaInterface.currentTime) : 0;
         const mediaCurrentTimeHumanReadable = dayjs().startOf('day').second(mediaCurrentTimeInSeconds).format('H:mm:ss');
         setShareLinkState({
            ...shareLinkState,
            mediaCurrentTimeInSeconds: mediaCurrentTimeInSeconds,
            startAtCurrentTimeLabel: mediaCurrentTimeInSeconds > 0 ? `${t('share.startAt')} ${mediaCurrentTimeHumanReadable}` : ''
         });
      });
   }, []);

   const getLink = (includeStartTime: boolean) => {
      const currentTime = dayjs().startOf('day').second(shareLinkState.mediaCurrentTimeInSeconds);

      let currentTimeQueryString = `${shareLinkState.mediaCurrentTimeInSeconds}s`;
      if (currentTime.hour() > 0) {
         currentTimeQueryString = currentTime.format('H[h]m[m]s[s]');
      } else if (currentTime.minute() > 0) {
         currentTimeQueryString = currentTime.format('m[m]s[s]');
      }

      return includeStartTime ? UpsertQueryParamInUrl(link, Constants.queryParams.smartPlayerStartTime, currentTimeQueryString) : link;
   };

   const onStartAtCurrentTimeClick = () => {
      const includeCurrentTimeInMediaLink = !shareLinkState.includeCurrentTimeInMediaLink;

      setShareLinkState({
         ...shareLinkState,
         includeCurrentTimeInMediaLink: includeCurrentTimeInMediaLink,
         mediaLink: getLink(includeCurrentTimeInMediaLink)
      });
   };

   const startAtCurrentTimeOption = shareLinkState.mediaCurrentTimeInSeconds > 0 ? (
      <div className="form-field-group current-time-option">
         <InlineLabeledCheckbox isChecked={shareLinkState.includeCurrentTimeInMediaLink} isCentered={false} onClick={onStartAtCurrentTimeClick} checkboxAlignment={TscCheckboxAlignment.left} labelText={shareLinkState.startAtCurrentTimeLabel} />
      </div>
   ) : null;

   return (
      <>
         <CopyLinkFormCollection link={shareLinkState.mediaLink} text={text} setCurrentCopy={setCurrentCopy} currentCopy={currentCopy}/>
         {startAtCurrentTimeOption}
      </>
   );
};

export default ShareMediaLink;

interface IShareMediaLinkState {
   mediaLink: string;
   mediaCurrentTimeInSeconds: number;
   includeCurrentTimeInMediaLink: boolean;
   startAtCurrentTimeLabel: string;
}

export interface IShareMediaLinkProps {
   link: string;
   text: string;
   setCurrentCopy?: (e: string) => void;
   currentCopy?: string;
}
