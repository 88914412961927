import React, {useState, useEffect} from 'react';
import {ControlButton, CustomSelectInput, TscButtonType, TscThemeName, TscThemeNames, TscStyles, SlideToggle} from '@techsmith/tsc-cloud-style-guide';
import {Trans, useTranslation} from 'react-i18next';
import {getCollectionPrivacyOptions} from '../../util/CollectionPrivacyOptionsGenerator';
import '../../static/css/modal.less';
import styled from 'styled-components';
import {MinWidthButtonWrapper, StyledWarning} from '../util/StyledElements';
import {SetupWorkerState} from '../../model/workerState';
import config from '../../service/config';
import profileApi from '../../service/profileApi';
import withMemoizedContexts from '../../context/contextContainerHoC';
import Constants, {CollectionPrivacyTypes, CollectionRoleTypes, EntryPoint} from '../../constants/Constants';
import {themeStore} from '../../context/themeProvider';
import {compareMembersByRole, ICollectionModel, IMemberDetailsModel, IMemberDetailsWithProfileImageModel, IUserProfileWithEmailModel} from '../../model/appModel';
import {collectionsStore, removeCollectionFromState} from '../../context/collectionsProvider';
import logging from '../../service/logging';
import collectionApi from '../../service/collectionApi';
import CssUtil from '../../util/CssUtil';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import {CollectionDefaultRoleOptionModel} from '../../model/collectionDefaultRoleOptionModel';
import CollectionMembersWarningModal, {CollectionMembersWarningModalType} from './CollectionMembersWarningModal';
import TypeAheadListManager from './CollectionMembersModal/TypeAheadListManager';
import MemberAlreadyExistsError from '../../class/memberExistsError';
import copy from 'copy-to-clipboard';

const PrivacySelectWrapper = styled.div`
   padding-right: 2px;
   text-align: start;
`;

const FieldTitle = styled.div`
   margin-bottom: 0.3125rem;
   font-size: .875rem;
   font-weight: 600;
   height: 1.25rem;
`;

const backgroundAlpha = 0.1;
const ShareOptionsWrapper = styled.div<{hasWarning: boolean}>`
   display: flex;
   flex-direction: column;
   background-color: ${props => props.hasWarning && CssUtil.toRgba(TscStyles.color.message.caution, backgroundAlpha)};
   border: ${props => props.hasWarning && `1px solid ${TscStyles.color.message.caution}`};
   border-radius: ${TscStyles.border.radius.lg};
   margin-bottom: 1rem;
`;

const PrivacyToggleWrapper = styled.div<{theme: TscThemeNames}>`
   display: flex;
   flex-direction: column;
   padding: 1rem;
   background-color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.mediumDark : TscStyles.color.ui.dawn.lightMedium};
   border: 1px solid ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : TscStyles.color.ui.dawn.medium};
   border-radius: ${TscStyles.border.radius.lg};
`;

const InvalidPrivacyWrapper = styled.span`
   text-align: center;
   padding: 1rem;
`;

const BoldText = styled.span`
   font-weight: ${TscStyles.font.weight.semibold};
   display: inline-flex;
`;

export const CollectionPermissions = styled(CustomSelectInput)`
   .collection-permissions-container, .collection-permissions{
      border-radius: ${TscStyles.border.radius.lg};
   }
`;

const decorateMemberWithProfileImageUrl = (user: IMemberDetailsModel): IMemberDetailsWithProfileImageModel => ({...user, profileImageUrl: profileApi.getProfileImageUrl(user.TechSmithId)});

const defaultWarningModalState = {
   showModal: false,
   modalType: null as CollectionMembersWarningModalType,
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   onConfirm: () => {}
};

export const ShareCollectionModalBase: React.FC<IShareCollectionModalProps & IStateMappedProps> = ({onClose, collection, theme, activeCollection}) => {
   const {t} = useTranslation();
   const [state, setState] = SetupWorkerState();
   const [errorText, setErrorText] = useState('');
   activeCollection = collection || activeCollection;
   const [selectedPrivacyOption, setSelectedPrivacyOption] = useState<CollectionPrivacyTypes>(activeCollection?.Privacy);
   const [defaultCollectionRole, setDefaultCollectionRole] = useState(activeCollection?.DefaultRole);
   const [isChildWorking, setIsChildWorking] = useState(false);
   const [warningModalInfo, setWarningModalInfo] = useState(defaultWarningModalState);
   const [copyButtonText, setCopyButtonText] = useState<string>(t('collection.copyButtonText'));
   const isPrivate = selectedPrivacyOption === CollectionPrivacyTypes.invite;
   const [members, setMembers] = useState<IMemberDetailsWithProfileImageModel[]>(Object.assign([], activeCollection?.MemberDetails.map(m => decorateMemberWithProfileImageUrl(m))));
   const shareLink = `${config.environmentData.PresentationRootUrl}${Constants.navigation.collectionsViewBasePath}/${activeCollection?.Id}`;

   const updatePrivacy = async (newPrivacy: CollectionPrivacyTypes) => {
      setState({...state, hasError: false});
      if (activeCollection.Privacy !== newPrivacy) {
         setState({...state, isWorking: true});
         try {
            await collectionApi.updatePrivacy(activeCollection.Id, newPrivacy);
            activeCollection.Privacy = newPrivacy;
            setState({isWorking: false, hasError: false});
         } catch {
            setState({isWorking: false, hasError: true});
         }
      }
   };

   const togglePrivacyOption: React.MouseEventHandler = async () => {
      const initialPrivacyOption = selectedPrivacyOption;
      try {
         if (isPrivate) {
            setSelectedPrivacyOption(CollectionPrivacyTypes.public);
            await updatePrivacy(CollectionPrivacyTypes.public);
         } else {
            setSelectedPrivacyOption(CollectionPrivacyTypes.invite);
            await updatePrivacy(CollectionPrivacyTypes.invite);
         }
      } catch {
         setSelectedPrivacyOption(initialPrivacyOption);
      }
   };

   useEffect(() => {
      setDefaultCollectionRole(activeCollection?.DefaultRole);
      setMembers(Object.assign([], activeCollection?.MemberDetails.map(m => decorateMemberWithProfileImageUrl(m))));
   }, [activeCollection]);

   const updateChildWorkerState = (newIsWorking: boolean, error: string) => {
      setIsChildWorking(newIsWorking);
      setErrorText(error);
   };

   const updateWorkerState = (newIsWorking: boolean, error: string) => {
      setState({...state, isWorking: newIsWorking});
      setErrorText(error);
   };

   const warnIfPendingChanges = () => {
      const defaultRoleChanged = defaultCollectionRole !== activeCollection.DefaultRole;
      const addedMembers = members.filter(m => !activeCollection.MemberDetails.some(existingMember => m.TechSmithId === existingMember.TechSmithId));
      const removedMembers = activeCollection.MemberDetails.filter(existingMember => !members.some(m => m.TechSmithId === existingMember.TechSmithId));
      const roleChanges = members.filter(m => !addedMembers.some(a => a.TechSmithId === m.TechSmithId) && activeCollection.MemberDetails.find(existingMember => m.TechSmithId === existingMember.TechSmithId)?.Role !== m.Role);

      const changesMade = defaultRoleChanged || addedMembers.length > 0 || removedMembers.length > 0 || roleChanges.length > 0;
      if (changesMade) {
         setWarningModalInfo({showModal: true, modalType: CollectionMembersWarningModalType.DiscardChanges, onConfirm: () => onClose()});
      } else {
         onClose();
      }
   };

   const onSave = async () => {
      updateWorkerState(true, '');

      try {
         // Important! Do not change the order of any of the steps below. Changes must be applied in the following order to ensure that the collection always has at least 1 manager.
         // 1. Update the default role for the collection (technically doesn't have to be first but makes the most sense)
         // 2. Add new members
         // 3. Apply role changes, in order, (managers, contributors, participants)
         // 4. Remove members
         // 5. Remove the collection from state or refresh it so changes are reflected in the UI

         // Step1 (Update the default role for the collection)
         const defaultRoleChanged = defaultCollectionRole !== activeCollection.DefaultRole;
         if (defaultRoleChanged) {
            await collectionApi.updateDefaultRole(activeCollection.Id, defaultCollectionRole);
         }

         // Step 2 (Add new Members)
         const addedMembers = members.filter(m => !activeCollection.MemberDetails.some(existingMember => m.TechSmithId === existingMember.TechSmithId));
         await Promise.all(addedMembers.map(m => collectionApi.addMember(activeCollection.Id, m.EmailAddress, m.Role)));

         // Step 3 (Apply role changes in order)
         // We need to apply role changes in order otherwise you could be attempting to demote your last manager before you apply the role change to promote a new one. That would result in a 409 conflict response from the server.
         const roleChanges = members.filter(m => !addedMembers.some(a => a.TechSmithId === m.TechSmithId) && activeCollection.MemberDetails.find(existingMember => m.TechSmithId === existingMember.TechSmithId)?.Role !== m.Role);
         roleChanges.sort(compareMembersByRole);
         await Promise.all(roleChanges.map(m => collectionApi.changeRole(activeCollection.Id, m.TechSmithId, m.Role)));

         // Step 4 (Remove members)
         const removedMembers = activeCollection.MemberDetails.filter(existingMember => !members.some(m => m.TechSmithId === existingMember.TechSmithId));
         await Promise.all(removedMembers.map(m => collectionApi.removeMember(activeCollection.Id, m.TechSmithId)));

         // Step 5 (Update the UI)
         const leftCollection = removedMembers.some(m => m.TechSmithId === config.user.TechSmithId);
         if (leftCollection) {
            removeCollectionFromState(activeCollection, false);
         }

         updateWorkerState(false, '');
      } catch (e) {
         logging.error('Error managing collection', e);
         updateWorkerState(false, t('general.errorText'));
      }
   };

   const handleAddMember = (addedMember: IUserProfileWithEmailModel) => {
      if (!members.some(m => m.TechSmithId === addedMember.techSmithId)) {
         const membersCopy = Object.assign([], members);
         const decoratedMember: IMemberDetailsWithProfileImageModel = {
            DisplayName: addedMember.displayName,
            EmailAddress: addedMember.email,
            FirstAccessDate: null,
            Role: defaultCollectionRole,
            TechSmithId: addedMember.techSmithId,
            profileImageUrl: addedMember.profileImageUrl
         };
         membersCopy.push(decoratedMember);
         setMembers(membersCopy);
      } else {
         throw new MemberAlreadyExistsError(t('collection.modal.members.memberAlreadyExists'));
      }
   };

   const removeFromMembers = (member: IMemberDetailsWithProfileImageModel) => {
      setWarningModalInfo(defaultWarningModalState);
      const memberIndex = members.findIndex(m => m.TechSmithId === member.TechSmithId);
      if (memberIndex >= 0) {
         const membersCopy = Object.assign([], members);
         membersCopy.splice(memberIndex, 1);
         setMembers(membersCopy);
      }
   };

   const handleRemoveMember = (member: IMemberDetailsWithProfileImageModel) => {
      if (member.TechSmithId === config.user.TechSmithId && member.Role === CollectionRoleTypes.manager) {
         setWarningModalInfo({showModal: true, modalType: CollectionMembersWarningModalType.RemoveSelf, onConfirm: () => removeFromMembers(member)});
      } else {
         removeFromMembers(member);
      }
   };

   const changeMemberRole = (member: IMemberDetailsWithProfileImageModel, role: CollectionRoleTypes) => {
      setWarningModalInfo(defaultWarningModalState);
      const memberIndex = members.findIndex(m => m.TechSmithId === member.TechSmithId);
      if (memberIndex >= 0) {
         member.Role = role;
         const membersCopy = Object.assign([], members);
         membersCopy.splice(memberIndex, 1, member);
         setMembers(membersCopy);
      }
   };

   const onRoleChange = (member: IMemberDetailsWithProfileImageModel, role: CollectionRoleTypes) => {
      if (member.TechSmithId === config.user.TechSmithId && member.Role === CollectionRoleTypes.manager) {
         setWarningModalInfo({showModal: true, modalType: CollectionMembersWarningModalType.DemoteSelf, onConfirm: () => changeMemberRole(member, role)});
      } else {
         changeMemberRole(member, role);
      }
   };

   const onCloseWarning = () => {
      setWarningModalInfo(defaultWarningModalState);
   };

   const onDefaultRoleChange = (role: CollectionRoleTypes) => {
      setDefaultCollectionRole(role);
   };
   
   useEffect(() => {
      const saveRoleChanges = async () => {
         if (activeCollection && defaultCollectionRole && members) {
            await onSave();
            activeCollection.DefaultRole = defaultCollectionRole;
            activeCollection.MemberDetails = members.map(member => ({...member}));
         }
      };
      void saveRoleChanges();
   }, [defaultCollectionRole, members]);

   const defaultRoleOptionsMap = new Map<CollectionRoleTypes, CollectionDefaultRoleOptionModel>();
   defaultRoleOptionsMap.set(CollectionRoleTypes.contributor, {
      value: CollectionRoleTypes.contributor,
      label: CollectionRoleTypes.contributor,
      subtext: t('collection.modal.members.contributorRoleSubText')
   });
   defaultRoleOptionsMap.set(CollectionRoleTypes.participant, {
      value: CollectionRoleTypes.participant,
      label: CollectionRoleTypes.participant,
      subtext: t('collection.modal.members.participantRoleSubText')
   });

   const privacySetting = getCollectionPrivacyOptions().get(CollectionPrivacyTypes.invite).label;

   const userCanAdmin = config.entryPoint !== EntryPoint.feed && config.user.IsSignedIn && (
      config.isAdmin ||
      activeCollection?.Role === CollectionRoleTypes.manager
   );
   
   const shareRequirement = userCanAdmin ? t('share.shareRequirement.manager') : t('share.shareRequirement.notManager');

   const onCopyClick = () => {
      copy(shareLink, {format: 'text/plain'});
      setCopyButtonText(t('general.copied'));
   };

   const allowAddUser = !config.featureSwitches.DisableInternalUserCollectionManagement;

   return (
      <>
         {warningModalInfo.showModal && <CollectionMembersWarningModal modalType={warningModalInfo.modalType} onClose={onCloseWarning} onConfirm={warningModalInfo.onConfirm}/>}
         <BasicModalPortal visible={true} title={t('share.shareCollection').replace('{{collectionName}}', activeCollection?.Name)} width="30rem" onClose={userCanAdmin ? warnIfPendingChanges : onClose} themeName={theme} enableFocusTrap={true} isLoading={state.isWorking}>
            <div className="modal-body">
               <div className="form-body themeable-section">
                  {userCanAdmin && !!errorText && <StyledWarning id="error-message">{errorText}</StyledWarning>}
                  {userCanAdmin && <div className="form-field-listing t-privacy-select">
                     <PrivacySelectWrapper>
                        {state.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
                     </PrivacySelectWrapper>
                  </div>}

                  {userCanAdmin && <div className="form-field-group">
                     <FieldTitle>{t('collection.viewAccessTitle')}</FieldTitle>
                     <PrivacyToggleWrapper theme={theme}>
                        <SlideToggle labelText={t('privacy.collection.togglePrivacyLabel')} isChecked={isPrivate} onClick={togglePrivacyOption}/>
                     </PrivacyToggleWrapper>
                  </div>}

                  <ShareOptionsWrapper hasWarning={selectedPrivacyOption === CollectionPrivacyTypes.invite}>
                     {selectedPrivacyOption === CollectionPrivacyTypes.invite ?
                        <InvalidPrivacyWrapper>
                           <Trans i18nKey="share.collectionInvalidPrivacyState">
                              Collections set to
                              <BoldText>{{privacySetting}}</BoldText>
                              &nbsp;cannot be shared. {{shareRequirement}}
                           </Trans>
                        </InvalidPrivacyWrapper> :
                        <>
                           {userCanAdmin && <div className="form-field-group">
                              <CollectionPermissions testId="collection-permissions" className="collection-permissions" themeName={theme} options={[...defaultRoleOptionsMap.values()]} width="100%" closeMenuOnClick={true} label={t('collection.modal.members.defaultRoleLabel')} onChange={onDefaultRoleChange} value={defaultCollectionRole}/>
                           </div>}

                           {!userCanAdmin && <FieldTitle>{t('share.linkText')}</FieldTitle>}
                           <ControlButton
                              label={copyButtonText}
                              buttonType={TscButtonType.primary}
                              themeName={theme}
                              onClick={onCopyClick}
                              width="100%"
                              testId="share-modal-copy-button"
                           />
                        </>
                     }
                  </ShareOptionsWrapper>

                  <div className="form-field-group">
                     <FieldTitle>{t('collection.accessListTitle')}</FieldTitle>
                     <TypeAheadListManager
                        labelText={t('collections.options.manageMembers')}
                        members={members}
                        isWorking={isChildWorking}
                        removeMember={userCanAdmin ? handleRemoveMember : null}
                        addMember={userCanAdmin && allowAddUser ? handleAddMember : null}
                        onRoleChange={userCanAdmin ? onRoleChange : null}
                        updateWorkerState={updateChildWorkerState}
                     />
                  </div>
                  
                  <div className="button-group">
                     <MinWidthButtonWrapper>
                        <ControlButton
                           label={t('general.close')}
                           buttonType={TscButtonType.secondary}
                           themeName={theme}
                           onClick={userCanAdmin ? warnIfPendingChanges : onClose}
                           testId="share-modal-close-button"
                        />
                     </MinWidthButtonWrapper>
                  </div>
               </div>
            </div>
         </BasicModalPortal>
      </>
   );
};

export interface IShareCollectionModalProps {
   onClose(): void;
   collection?: ICollectionModel;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
   activeCollection: ICollectionModel;
}

const mapStatesToProps = (
   {onClose, collection}: IShareCollectionModalProps,
   {theme}: Partial<IStateMappedProps>,
   {activeCollection}: Partial<IStateMappedProps>
): IShareCollectionModalProps & IStateMappedProps => ({onClose, collection, theme, activeCollection});

export default withMemoizedContexts(mapStatesToProps, themeStore, collectionsStore)(ShareCollectionModalBase);
