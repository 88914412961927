import ApiUtil from '../util/ApiUtil';
import StringUtil from '../util/StringUtil';
import Constants from '../constants/Constants';
import {IFolderTreeNode} from '../model/folderTreeNode';
import {mutate} from 'swr';
import {createEvent} from '../util/EventUtil';
import {IBaseUser} from '../model/baseUser';
import {IMinimalFolder} from '../model/minimalFolder';

const folderBaseUrl = '/api/v1/folder';

const updateParentFolder = async (mediaHashes: string[], folderHashes: string[], parentFolderHash: string, techSmithId: string, teamId?: string | null) => {
   parentFolderHash = StringUtil.isNullOrWhiteSpace(parentFolderHash) ? 'root' : parentFolderHash;
   return await ApiUtil.executePutAsync(`${folderBaseUrl}/${parentFolderHash}`,
      [Constants.statusCodes.noContent], {
         TechSmithId: techSmithId,
         TeamId: teamId,
         MediaHashes: mediaHashes,
         FolderHashes: folderHashes
      });
};

const refetchFoldersForUser = async (techSmithId: string) => {
   await mutate(`user/${techSmithId}/folders`);
};

const sortSubFolders = (newFolders: IFolderTreeNode[]) => {
   newFolders.sort((a, b) => a.Name.localeCompare(b.Name));
   newFolders.forEach(folder => {
      folder.SubFolders.sort((a, b) => a.Name.localeCompare(b.Name));
   });

   return newFolders;
};

const populateOwner = (folderTreeNode: IFolderTreeNode, ownerTechSmithId: string) => {
   folderTreeNode.Owner = {
      TechSmithId: ownerTechSmithId,
      EmailAddress: '',
      DisplayName: ''
   } as IBaseUser;
   folderTreeNode.SubFolders.forEach(subFolder => {
      populateOwner(subFolder, ownerTechSmithId);
   });
};

const getFolderTree = async (techSmithId?: string, teamId?: string): Promise<IFolderTreeNode> => {
   if (!teamId && (!techSmithId || techSmithId === Constants.nullTechSmithId)) {
      throw new Error('TechSmithId and TeamId is null when getting folders');
   }
   
   const response = await ApiUtil.executeGetWithoutCacheAsync(`${folderBaseUrl}/foldertree?${teamId ? 'teamId' : 'userId'}=${teamId ?? techSmithId}`, [Constants.statusCodes.ok]);

   const rootFolderTreeNode = response.data as IFolderTreeNode;
   sortSubFolders(rootFolderTreeNode?.SubFolders ?? []);
   populateOwner(rootFolderTreeNode, teamId ?? techSmithId);
   return rootFolderTreeNode;
};

const getFolderBranch = async (folderHash: string): Promise<IFolderTreeNode> => {
   if (!folderHash) {
      throw new Error('folderHash is null when getting folder branch');
   }

   const response = await ApiUtil.executeGetWithoutCacheAsync(`${folderBaseUrl}/folderbranch?folderHash=${folderHash}`, [Constants.statusCodes.ok]);
   const folderBranch = response.data as IFolderTreeNode;
   sortSubFolders(folderBranch?.SubFolders ?? []);
   return folderBranch;
};

const createFolder = async (techSmithId: string, teamId: string | null, name: string, parentFolder = ''): Promise<IMinimalFolder> => {
   const response = await ApiUtil.executePostAsync(`${folderBaseUrl}/${parentFolder}`, [Constants.statusCodes.created], {TechSmithId: techSmithId, Name: name, TeamId: teamId});
   return response.data as IMinimalFolder;
};

const renameFolder = async (hash: string, name: string) => await ApiUtil.executePatchAsync(`${folderBaseUrl}/${hash}`, [Constants.statusCodes.noContent], {Name: name});

const deleteFolder = async (hash: string) => await ApiUtil.executeDeleteAsync(`${folderBaseUrl}/${hash}`, [Constants.statusCodes.noContent]);

const changeOwner = async (hash: string, newOwnerEmail: string) => await ApiUtil.executePatchAsync(`${folderBaseUrl}/${hash}/changeowner`, [Constants.statusCodes.noContent], {newOwnerEmail: newOwnerEmail});

const refetchFolders = async (techSmithId: string) => {
   await refetchFoldersForUser(techSmithId);
   window.dispatchEvent(createEvent(Constants.updatedFoldersWindowEvent));
};

export default {
   updateParentFolder,
   getFolderTree,
   getFolderBranch,
   createFolder,
   deleteFolder,
   renameFolder,
   changeOwner,
   refetchFolders
};
